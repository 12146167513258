html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.App-header {
  background-color: #3f51b5;
  position: fixed;
  width: 100%;
  color: white;
  padding: 10px;
  text-align: center;
}

main {
  flex-grow: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.dropdown .dropdown-menu {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Replace $dropdown-box-shadow with an actual value */
  z-index: 996;
}

.dropdown .dropdown-menu.dropdown-xl {
  width: 300px; /* Replace $dropdown-xl-width with an actual value */
}

@media (max-width: 575.98px) {
  .dropdown .dropdown-menu.dropdown-xl {
      width: 100%;
      left: 10px !important;
      right: 10px !important;
  }

  .topbar .dropdown {
      position: static;
  }

  .topbar .dropdown .dropdown-menu {
      width: calc(100% - 20px) !important;
      left: 10px !important;
      right: 20px !important;
      transform: none !important;
      top: 100% !important;
  }
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  background-color: red; /* Replace with the appropriate color code for $danger */
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background-color: green; /* Replace with the appropriate color code for $success */
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  background-color: yellow; /* Replace with the appropriate color code for $warning */
}