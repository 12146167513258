.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 40px;
}

.login-paper {
  padding: 20px;
  width: 100%;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.login-button {
  margin-top: 20px;
}
