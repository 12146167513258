html, body, #root {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff; /* Background color for header */
    border-bottom: 1px solid #ddd; /* Optional: add border for distinction */
    z-index: 1000; /* Ensure header is on top */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  }
  
  .content {
    margin-top: 60px; /* Adjust based on header height */
    padding: 20px;
    height: calc(100vh - 60px); /* Adjust based on header height */
    overflow-y: auto;
  }
  

  .App {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .App-header {
    background-color: #3f51b5;
    position: fixed;
    width: 100%;
    color: white;
    padding: 10px;
    text-align: center;
  }
  
  main {
    flex-grow: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .Toastify__toast-theme--colored.Toastify__toast--error {
    background-color: red; /* Replace with the appropriate color code for $danger */
  }
  
  .Toastify__toast-theme--colored.Toastify__toast--success {
    background-color: green; /* Replace with the appropriate color code for $success */
  }
  
  .Toastify__toast-theme--colored.Toastify__toast--warning {
    background-color: yellow; /* Replace with the appropriate color code for $warning */
  }