html, body, #root {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  .App {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .App-header {
    background-color: #3f51b5;
    position: fixed;
    width: 100%;
    color: white;
    padding: 10px;
    text-align: center;
  }

  main {
    flex-grow: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .Toastify__toast-theme--colored.Toastify__toast--error {
    background-color: red; /* Replace with the appropriate color code for $danger */
}

.Toastify__toast-theme--colored.Toastify__toast--success {
    background-color: green; /* Replace with the appropriate color code for $success */
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
    background-color: yellow; /* Replace with the appropriate color code for $warning */
}

/* Add this to your CSS file */
.table-cell-center {
  text-align: center;
}

.sorting-icon {
  display: inline-block;
  transition: none; /* Remove transition effects */
}
.sorting-icon:hover {
  cursor: default; /* Change cursor to default to indicate no action */
}